import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-scroll'

export interface Props {
  buttonLabel?: string
  ctas?: ButtonProps[]
  description?: string
  image?: ImageProps
  title?: string
  variant?: string
}

export const Stripe = memo(function Stripe({
  buttonLabel,
  ctas,
  description,
  image,
  title,
  variant,
}: Props) {
  return (
    <Container dial={4} row wrap stretch>
      <LeftSide className={variant === 'stars' ? 'stars' : undefined}>
        {image ? <Image {...image} /> : null}
      </LeftSide>
      <RightSide dial={4}>
        {title ? (
          <Fade bottom distance="3.75rem">
            <Title>{title}</Title>
          </Fade>
        ) : null}
        {description ? (
          <Fade bottom distance="3.75rem">
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </Fade>
        ) : null}
        {buttonLabel ? (
          <Fade bottom>
            <CTA to="section-form" smooth>
              <CTALabel>{buttonLabel}</CTALabel>
            </CTA>
          </Fade>
        ) : null}
        {ctas
          ? ctas.map((item, index) => (
              <Fade bottom key={index}>
                <CTAS {...item} />
              </Fade>
            ))
          : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  padding-bottom: 9rem;
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
        &:before {
          right: 0;
          left: auto;
        }
      }
      &:last-child {
        padding-right: 8.472vw;
        padding-left: 10vw;

        @media (max-width: 1199px) {
          padding-right: 3.75rem;
          padding-left: 1.5rem;
        }

        @media (max-width: 767px) {
          order: 2;
          padding-right: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 5.625rem;
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding-bottom: 36%;
  position: relative;
  &.stars {
    &:before {
      background-image: url('/stars.svg');
      opacity: 1;
    }
  }
  &:before {
    content: '';
    width: 50%;
    height: 100%;
    background: url('/wave.svg') left center no-repeat;
    background-size: cover;
    opacity: 0.16;
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: calc(100% - 7.5rem);
    object-fit: cover;
    position: absolute;
    top: 3.75rem;
    left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 70%;
    &:before {
      display: none;
    }

    img {
      height: 100%;
      top: 0;
    }
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  padding: 0 10vw 0 8.472vw;

  @media (max-width: 1199px) {
    padding: 0 1.5rem 0 3.75rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 3rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3rem;

  @media (max-width: 767px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.875rem;
`

const CTA = styled(Link)`
  display: inline-block;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  overflow: hidden;
  margin-top: 3rem;
  padding: 1.1875rem 1.875rem;
  position: relative;
  &:hover {
    &:after {
      top: 0;
    }
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    top: 100%;
    left: 0;
    transition: 0.3s ease-out;
  }

  @media (max-width: 767px) {
    margin-top: 2.25rem;
    padding: 0.875rem 1.5rem;
  }
`

const CTALabel = styled.span`
  position: relative;
  z-index: 2;
`

const CTAS = styled(Button)`
  display: inline-block;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  overflow: hidden;
  margin-top: 3rem;
  padding: 1.1875rem 1.875rem;
  position: relative;
  &:hover {
    &:after {
      top: 0;
    }
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    top: 100%;
    left: 0;
    transition: 0.3s ease-out;
  }

  @media (max-width: 767px) {
    margin-top: 2.25rem;
    padding: 0.875rem 1.5rem;
  }
`
